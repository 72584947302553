    /* h2 {
        font-size: 20px !important;
        font-weight: 700;
    } */
    .popupbox .MuiBackdrop-root {
        background-color: transparent;
    }
    .MuiBackdrop-root {
        backdrop-filter: blur(10px);
    }

    .popupbox.arrow-top {
        margin-top: 30px;
    }

    .popupbox .MuiPaper-elevation24 {
        box-shadow: none;
    }

    /* .MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
        border-radius: 12px;
    } */

    .popupbox .MuiContainer-root {
        padding-left: 0px;
        padding-right: 0px;
    }

    .popupbox .MuiDialog-paperWidthSm {
        border-radius: 20px;
        position: absolute;
        right: 200;
    }

    .btn-green {
        background: #00A7A2;
        color: #fff;
        padding: 15px 30px;
        border-radius: 32px;
        border: none;
        font-size: 14px;
        font-weight: 700;
        min-width: 136px;
        cursor: pointer;
    }

    /* .css-view-1dbjc4n button.dropdown-btn {
        padding: 0 10px !important;
    } */
    .mui-dropdown__menu.dropdown-menu {
        border: 1px solid #8083A3;
        border-radius: 4px;
    }

    .mui-dropdown__menu>li>a {
        padding: 3px 10px;
    }

    .site-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        /* Divide header into three parts */
        background-color: #fff;
        padding: 10px;
        flex-wrap: wrap;
        position: relative;
        z-index: 2;
    }

    .header-svg {
        position: absolute;
        right: 15px;
        fill: #858585;
    }

    /* .header-right>div:first-child {
        margin-right: 10px;
    } */

    .header-logo {
        max-width: 120px;
        min-width: 120px
    }

    .header-navigation {
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        margin-left: 20px;
    }

    .header-content {
        display: flex;
        align-items: center;
        gap: 10px;
        /* flex: 1; */
        justify-content: flex-end;
    }

    /* .header-content>* {
        margin-right: 10px;
    } */

    .header-right input[type="search"] {
        padding: 14px;
        border: 1px solid #E4E6E8;
        border-radius: 30px;
        font-size: 14px;
        max-width: 220px;
    }

    input[type="search"] {
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAQCAYAAADwMZRfAAAABHNCSVQICAgIfAhkiAAAAsFJREFUOE+Nkk1IVFEUx895X47TSx0aJWwqcSbfzJsPMYiQIKJFtAi0TdQiKTDcREktbJO1qSgirKTARZGBBBEGETFRELTIFjGTjR9vRgPRSrA00hmd5t17OyNOpIv07s7X7xz+94+w/KEnGHTpoOwQKDVIIMICsAgEjHEQL9Hmryyrf5xG7H/HsBB4PJ7idSXufSDjKeQQAYQk1SYR0BYgXBT7KM4IBl2zdrrnazL5vTC7BDE1I6AcBVm+QIlxDvyWbUNMstOTmUzG1vVyFyjCjygfpvohAfBY5NLnkkugRYjPX1evKPCANg7Y87Mto6OjU5Sm3uWv3DR1FxadkRDaOMLV5KfYJepgaJqmxlDrINru3xwOfh6Kp1YOr9TNH6rroVzA5vz4yODHGPp8vnLZofcR5KG9MHdlZGQkuwoEfIHIdkWSnwmEdisRu4/bzMhOWVKeMiFOpgZiT1YD5Os1NTVuWdN7Oefvspmf7WiY4QaUlQ7OWHNysP/1WiDV1dWlmrP0Hok2M82zrWgEI3tRkrvpkpZUIv58LZAt4bDLyeVHAJj8NSO1odcb2qw61D6B4pqViHfm1V4N5A0GfQqqUfq/29ZAvBMXTVZW0UPCOufZ/LGxoaFvq0GMYO15CaUmssSJ4UT8Td4nWBOobZRk6a4Q0GUNxNr/B9kWCh2QQe0mPXpJj9NTg4Nzi2ZzG8Z6t+K8SNZuIVA3y7LLCwvTPyYmJhaWTKdu8vtLdCxuRBluEOAL2rnm4eHE+0WzFbZ6vZEKxSGdpTOP0JmzlH/BhbBkwCzp5aGD99CSIOnQJ4R4iwgNjMH11FA8+heSh1VWVjr1Mnc9grQfJdxFQ1tpSKXSFG3/AFxEhZ2JMlUtU1G9Q+CNjIumZZDCVVVVVQ5N26Ajco0xJjEHz2m5XMayrDT18LyOhhEKk79as5i7+QfnkhzNvK0+ngAAAABJRU5ErkJggg==) no-repeat;
        cursor: pointer;
        background-position: 10px;
    }

    .header-right .Select {
        width: 200px;
    }

    .header-right .Select .Select-control {
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 16px;
    }

    .image-container {
        display: flex;
        align-items: center;
        gap: 10px;
        /* flex-wrap: wrap; */
    }

    .image-container img {
        width: 20px;
        height: 20px;
    }

    .img-content span {
        position: absolute;
        bottom: 3px;
        left: 6px;
        display: block;
        line-height: 15px;
        font-size: 12px;
        font-weight: 600;
        color: #222;
        cursor: pointer;
    }

    .img-content {
        position: relative;
    }

    /* ul.main-list {
        margin-left: 10px;
    } */

    .image-container img.img-large {
        width: 71px;
        height: 65px;
    }

    .header-right>ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;
    }

    .header-right ul li a {
        color: #8083A3;
    }

    .bannerclass {
        /* background-image: url(../bannerimage.png); */
        background-size: cover;
        height: calc(75vh - 70px);
        position: relative;
    }

    .banner-containt {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        text-align: center;
        font-size: 20px;
        max-width: 700px;
        width: 100%;
    }
     .bannerclass a{
        position: absolute;
        bottom: -20px;
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: center;
    }

    .talent-slider-block h2 {
        font-size: 30px;
        color: #333;
        font-weight: 700;
        text-align: center;
        padding-bottom: 20px;
    }

    .talent-slider-block {
        padding: 60px 0;
    }

    .react-multi-carousel-list {
        padding-top: 20px;
    }

    .talent-block {
        display: flex;
        align-items: center;
        /* flex-wrap: wrap; */
        padding: 20px 25px;
        border: 1px solid #E4E6E8;
        max-width: 235px;
        border-radius: 20px;
        margin-bottom: 2%;
    }

    .talent-content {
        max-width: 150px;
        padding-left: 15px;
    }

    .talent-content h3 {
        font-size: 18px;
        color: #222222;
        font-weight: 700;
        margin-bottom: 0px;
    }

    .talent-content p {
        margin: 0px;
    }

    .talent-image {
        background: rgb(230, 246, 246, 0.68);
        width: 70px;
        height: 70px;
        border-radius: 50%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .talent-image img {
        /* width: 40px;
        /* height: 40px; */
        /* object-fit: cover;  */
        border-radius: 50%;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .userinfo {
        display: flex;
    }

    .border-block {
        padding: 25px;
        border: 1px solid #E4E6E8;
        border-radius: 14px;
        margin-bottom: 30px;
        box-shadow: none;
    }

    .border-block {
        padding: 25px;
        border: 1px solid #E4E6E8;
        border-radius: 14px !important;
        margin-bottom: 30px;
        box-shadow: none !important;
    }

    ul.recharts-default-legend {
        display: none;
    }

    .border-block h3 {
        font-size: 20px;
        font-weight: 700;
    }

    .border-block h2 {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 0;
    }

    .usertime {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .userinfo span {
        color: #8083A3;
        padding: 0 12px 0 5px;
    }

    .userinfo img {
        vertical-align: middle;
    }

    .bg-gray {
        background: rgb(245 245 250) !important;
        border: 1px solid #E4E6E8 !important;
        position: relative;
    }

    .usertime p {
        font-weight: 700;
        font-size: 20px;
        color: #222;
        padding: 10px 0 0;
        margin: 0px 0 10px;
    }

    .bg-gray .btn-green {
        width: 100%;
        margin-top: 25px;
    }

    .chips-container {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin: 20px 0;
    }

    .chip {
        margin: 5px;
    }

    .d-flex {
        display: flex;
        gap: 0px;
    }

    .p-0 {
        padding: 0;
    }

    /* .rating-review-wrapper {
        width: 25%;
        margin: auto;
        border: 1px solid #E4E6E8;
        border-radius: 14px;
    } */

    .rating-review-wrapper h2 {
        font-size: 20px;
        font-weight: 700;
        margin: 0;
    }

    .rating-heading {
        padding: 25px 25px 0;
        align-items: center;
        justify-content: space-between;
    }

    .rating-block {
        border-bottom: 1px solid #E4E6E8;
        padding: 25px;
    }

    .rating-block h6 {
        font-size: 16px;
        margin: 0 0 15px;
        font-weight: 700;
    }

    .review-block.d-flex {
        align-items: center;
        justify-content: space-between;
    }

    .review-content {
        padding-left: 8px;
    }

    .review-content p {
        font-size: 14px;
        font-weight: 600;
    }

    .review-content small {
        font-size: 10px;
        font-weight: 500;
        color: #8083A3;
        display: block;
    }

    .rating-star {
        background: rgba(230, 246, 246, 0.698039);
        border-radius: 320px;
        padding: 7px 10px;
        display: flex;
        align-items: center;
    }

    .rating-star span {
        font-weight: 700;
        font-size: 14px;
        color: #00A7A2;
        padding-left: 5px;
    }

    .rating-star svg {
        fill: orange;
    }

    .view-all span.link {
        font-weight: 700;
        font-size: 14px;
        display: block;
        text-align: center;
        padding: 20px 0;
        color: #00A7A2;
    }

    .lancerview-all span.link {
        font-weight: 700;
        font-size: 14px;
        display: block;
        text-align: center;
        padding: 20px 0;
        color:rgb(236, 149, 45);
    }

    .view-all .link svg {
        margin-left: 10px;
    }
    .lancerview-all .link svg {
        margin-left: 10px;
    }

    .project-table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0;
    }

    .header-row {
        background-color: lightgray;
    }

    .avatar {
        width: 50px;
        height: 50px;
        border-radius: 50%;
    }

    .freelancer-name {
        margin-left: 10px;
    }

    .ellipsis {
        font-size: 20px;
        cursor: pointer;
    }

    .container {
        max-width: 1280px;
        margin: auto;
        padding: 0 30px;
    }

    .main-wrapper {
        font-family: 'Inter', sans-serif;
    }

    .search-bar input {
        border: 1px solid #E4E6E8;
        border-radius: 31px;
        box-shadow: none;
        padding: 10px;
    }

    .search-bar button {
        background: #00A7A2;
        position: absolute;
        right: 55px;
        bottom: -5px;
    }

    .search-bar button:hover {
        background: #00A7A2;
    }

    .input-container {
        max-width: 500px;
        width: 100%;
        position: relative;
    }

    .project-block {
        padding-bottom: 25px;
    }

    .d-flex {
        display: flex;
    }

    .righttelent-wrapper {
        padding: 80px 0;
        line-height: 22px;
    }

    .block-heading {
        text-align: center;
        padding-bottom: 30px;
    }

    .block-heading h2 {
        margin: 0;
        font-size: 30px;
        font-weight: 700;
        color: #333333;
    }

    .block-heading p {
        margin: 0 0 20px;
        color: #8083A3;
        font-size: 18px;
        font-weight: 500;
    }

    .righttelent-block-wrapper,
    .news-block-wrapper {
        margin: 0 -15px;
    }

    .righttelent-block,
    .news-block {
        width: 25%;
        padding: 0 15px;
        text-align: center;
        position: relative;
    }

    .righttelent-image {
        background: rgba(230, 246, 246, 0.698039);
        border-radius: 50%;
        width: 100px;
        height: 100px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
    }

    .righttelent-image img {
        width: 50px;
        height: 50px;
        object-fit: cover;
    }

    .righttelent-content {
        margin-top: 30px;
    }

    .righttelent-content h6,
    .news-content h6 {
        font-size: 18px;
        font-weight: 700;
        margin: 0 0 10px;
        color: #333333;
    }

    .righttelent-content p {
        margin: 0;
        color: #8083A3;
        font-size: 14px;
        font-weight: 500;
    }

    .righttelent-block-wrapper {
        counter-reset: section;
    }

    .righttelent-image::before {
        counter-increment: section;
        content: counter(section);
        background: #fff;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        line-height: 30px;
        position: absolute;
        top: 90px;
        font-size: 12px;
        box-shadow: 0px 3px 4px rgba(153, 155, 168, 0.25098);
        color: #333333;
    }

    .righttelent-block:after {
        content: "";
        background: url("https://s3-alpha-sig.figma.com/img/fa6e/ae30/fcd2c54fa430809554641c3125d85932?Expires=1687737600&Signature=j6PlzVB0Hb9B5y62LViVFIs9N647eF2i4T-pXwZMyPzL3I~dp0zS~g1wU~Q8CHsUzoyIebDgfQ87VJly219oFmNxhIVYjTxxlBtcsfy8OSWv6-z0frpq6Br-1mEWISVwlHUc7ueXsMbcyY-tJDPIWnsoZDPkf0go6fZAIZ3SYNnuiL~rKioNP4YXd~jlEFYRYL4tqWbOwU-13h2sj~JLe3kOCdRoo7aBpURQ1VZM7xCq1jHau4kdz4QCCDH1DmTtlBx5G1d4VPUYtEIcArUz82hHmmPGrN~1SyEJOk-QEaX-7yCDPrHYOUnyxzBIo58c0-9jW2NRKWMhwvWJaZBBBA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4");
        width: 75px;
        height: 21px;
        position: absolute;
        top: 30px;
        right: -40px;
    }

    .righttelent-block:nth-child(odd):after {
        content: "";
        background: url("https://s3-alpha-sig.figma.com/img/e7be/5c06/0303acde30068a2e820c003a305553bb?Expires=1687737600&Signature=Mu8dhLpQl8LWBW6HCyWb~MKHsgfZ6Jobjv~ORubCzteDchLDEbM-e5A2eHn65ThHHzf103WtyAX6DU0YE8lGnElDkXZHwputKzIZJfmZwNTQlofr6OOgCAyHHiTuRH~G~Y4dO6gsVbtsCWbrM4dVQU~GJWo~CVi3gwPNGVfhAIo-Vm2HjV-tfWVEkLHBEnW6qQxW0zsErY2H~opb75xTHxaXOR7KpaDKLXgyHQBI-5qF2ewVr5j3FDZ8tRdgdpHQwOUH19LEeCE8kLVo2D1KU~Xl~01cB1lk1a-fI637X93WKvQnSLw3kcl2UpcC8XGJhBmtP5PszlNhjroyEqhELA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4");
        width: 75px;
        height: 21px;
        position: absolute;
        top: 30px;
        right: -40px;
    }

    .righttelent-block:last-child::after {
        display: none;
    }

    .news-wrapper {
        background: rgba(245, 245, 250, 0.698039);
        padding: 80px 0;
    }

    .news-image {
        width: 100%;
        background: linear-gradient(179.82deg, rgba(255, 255, 255, 0.0001) -13.92%, #000000 99.85%), url('../newsbanner.png') no-repeat;
        border-radius: 14px;
        height: 340px;
        background-size: cover;
    }

    .news-content {
        color: #fff;
        position: absolute;
        bottom: 0;
        left: 0;
        text-align: left;
        max-width: 300px;
        padding: 0 30px 20px;
    }

    .news-content p {
        color: #fff;
        opacity: 0.9;
        margin-bottom: 0 0 20px;
        font-size: 14px;
        font-weight: 500;
    }

    .news-content h6 {
        color: #fff;
    }

    .news-details.d-flex {
        align-items: center;
        justify-content: space-between;
    }

    .news-details-block img {
        vertical-align: middle;
        width: 26px;
        height: 26px;
        margin-right: 5px;
    }

    .news-details small {
        font-weight: 500;
        font-size: 10px;
        color: #fff;
        opacity: 0.9;
        display: inline-block;
    }

    .news-button {
        text-align: center;
        margin: 20px 0;
    }

    .news-button button.view-all {
        padding: 10px 20px;
        background: #FFFFFF;
        border: 1px solid rgba(228, 230, 232, 0.6);
        border-radius: 30px;
        cursor: pointer;
    }

    .news-button button svg {
        margin-left: 5px;
        vertical-align: top;
    }

    .news-label {
        text-align: right;
    }

    .news-label small {
        display: inline-block;
        color: #00A7A2;
        background: #fff;
        font-size: 10px;
        font-weight: 500;
        padding: 5px 10px;
        border-radius: 24px;
        margin: 15px 15px 0 0;
    }


    .search-bar {
        display: flex;
        align-items: center;
    }

    .search-icon {
        margin-right: 8px;
        /* Adjust the margin as needed */
    }

    .active-bids-wrapper {
        width: 25%;
        margin: auto;
        border: 1px solid #E4E6E8;
        border-radius: 14px;
    }

    .active-bids-wrapper h2 {
        font-size: 20px;
        font-weight: 700;
        margin: 0;
    }

    .active-bids-heading {
        padding: 25px 25px 0;
        align-items: center;
        justify-content: space-between;
    }

    .active-bids-block {
        border-bottom: 1px solid #E4E6E8;
        padding: 20px 25px;
    }

    .active-bids-block h6 {
        font-size: 16px;
        margin: 0 0 15px;
        font-weight: 700;
    }

    .active-bids-block-wrapper.d-flex {
        align-items: center;
        justify-content: space-between;
    }

    .active-bids-content {
        padding-left: 8px;
    }

    .active-bids-content p {
        margin: 0 0 5px;
        font-size: 14px;
        font-weight: 600;
    }

    .active-bids-content small {
        font-size: 10px;
        font-weight: 500;
        color: #8083A3;
        display: block;
    }

    .active-bids-details span {
        background: rgba(230, 246, 246, 0.698039);
        border-radius: 320px;
        padding: 7px 10px;
        display: block;
        margin-bottom: 5px;
    }

    .active-bids-details span {
        font-weight: 700;
        font-size: 14px;
        color: #00A7A2;
    }

    small.bid {
        font-size: 10px;
        font-weight: 500;
        display: block;
    }

    .view-all span.link {
        font-weight: 700;
        font-size: 14px;
        display: block;
        text-align: center;
        padding: 20px 0;
        color: #00A7A2;
    }

    .design-logo-block {
        padding: 20px 25px;
    }

    .design-logo-block span {
        color: #8083A3;
        font-weight: 500;
        display: inline-block;
    }

    .design-details p {
        margin: 0;
        color: #8083A3;
        font-weight: 500;
        font-size: 12px;
        padding: 0;
    }

    .design-details h5 {
        font-size: 16px;
        font-weight: 700;
        margin: 0;
    }

    .design-content.d-flex {
        gap: 50px;
    }

    .green-bg {
        background: #00A7A2 !important;
        border-radius: 23px !important;
        margin-right: 16px;
        flex-direction: row-reverse;
        padding: 20px !important;
        min-height: 47px;
    }

    .green-bg span {
        color: #fff;
        font-weight: 700;
        padding: 0;
    }

    .design-message {
        padding: 13px 20px !important;
        background: #F2F2F2 !important;
        border-radius: 23px !important;
        min-height: 47px;
    }

    .design-message img {
        margin: 0 !important;
    }

    .design-message span {
        color: #222222;
        padding-left: 10px;
        padding-right: 0;
    }

    .view-proposal {
        border-top: 1px solid #E4E6E8;
        padding: 20px 25px;
    }

    .custom-chip {
        width: 100%;
        justify-content: flex-start !important;
        margin-top: 20px;
        background: rgb(246, 247, 247);
        min-height: 40px;
        position: relative;
    }

    thead.MuiTableHead-root {
        background: #F2F2F2;
        color: #8083A3 !important;
    }

    .MuiTableCell-root {
        padding: 20px;
    }

    .table-heading {
        padding: 20px;
        justify-content: space-between;
    }

    thead.MuiTableHead-root {
        background: #F2F2F2;
        color: #8083A3 !important;
    }

    /* .table-heading input {
        border-radius: 30px;
        width: 100%;
        max-width: 500px;
        min-width: 480px;
        box-shadow:  none !important;
        border: 1px solid #E4E6E8 !important;
        position: relative;
        padding: 10px;
    } */

    .search-bar {
        box-shadow: none !important;
        position: relative;
        display: block !important;
    }

    .ForwardRef-searchContainer-7 {
        width: 98% !important;
        margin: 0 !important;
    }

    .ForwardRef-root-20 {
        height: 48px !important;
        position: relative !important;
    }

    .block-heading.d-flex {
        align-items: center;
        justify-content: space-between;
    }

    .copy-icon {
        position: absolute;
        right: 5px;
    }

    .design-logo-block p {
        line-height: 1.8;
        padding-bottom: 5px;
    }

    .custom-chip span {
        max-width: 650px;
    }

    .common-search {
        box-shadow: none !important;
        border: 1px solid #E4E6E8;
        border-radius: 30px !important;
        position: relative;
        width: 100%;
        max-width: 365px;
    }

    .common-search2 {
        box-shadow: none !important;
        border: 1px solid #E4E6E8;
        border-radius: 30px !important;
        position: relative;
        width: 100%;
        max-width: 300px;
    }

    .common-search button {
        position: absolute;
        top: 0;
        left: 0;
    }

    .common-search input {
        padding-left: 40px;
        font-weight: 600;
        color: #8083A3;
    }

    .common-search:first-child {
        border-radius: 30px 0 0 30px !important;
    }

    .common-search2 button {
        position: absolute;
        top: 0;
        left: 0;
    }

    .common-search2 input {
        padding-left: 22px;
    }

    .common-search2:first-child {
        border-radius: 30px 0 0 30px !important;
    }

    select.select-box {
        border: 1px solid #E4E6E8;
        height: 50px;
        border-radius: 0 30px 30px 0;
        max-width: 120px;
        padding-left: 15px;
    }

    .banner-containt h1 {
        font-weight: 700;
        margin-bottom: 24px;
    }

    ul.navigation {
        gap: 15px;
        margin-right: 20px;
    }

    ul.navigation li {
        font-size: 14px;
    }

    ul.navigation li a {
        color: black;
        text-decoration: none;

    }

    ul.navigation li a:hover {
        color: #00a7a2;
    }

    ul.navigation li.active a {
        color: #00A7A2;
        /* border-bottom: 5px solid #00A7A2; */
        position: relative;
    }

    ul.navigation li.active a:after {
        content: "";
        position: absolute;
        bottom: -25px;
        left: 0;
        width: 100%;
        height: 5px;
        background: #00a7a2;
        color: #00a7a2;
        border-radius: 5px;
    }

    /* .pie-block {
        transform: scale(1.4);
        margin-left: 18px;
    } */

    .container-with-dots {
        position: inherit !important;
    }

    .react-multiple-carousel__arrow--left {
        left: 55px !important;
        background: #fff !important;
        box-shadow: 0 3px 4px #999BA8;
    }

    .react-multiple-carousel__arrow--right {
        right: 65px !important;
        background: #fff !important;
        box-shadow: 0 3px 4px #999BA8;
    }

    .react-multiple-carousel__arrow::before {
        color: #000 !important;
    }

    .card {
        width: 100px;
        height: 100px;
        border-radius: 5px;
    }

    .card .percent {
        position: relative;
    }

    .card svg {
        position: relative;
        width: 100px;
        height: 100px;
        /* transform: rotate(-90deg);  */
    }

    .card svg circle {
        width: 100%;
        height: 100%;
        fill: none;
        stroke: lightgray;
        stroke-width: 5;
        stroke-linecap: round;
    }

    .card svg circle:last-of-type {
        stroke-dasharray: 250px;
        stroke-dashoffset: calc(250px - (250px * var(--percent)) / 100);
        stroke: green;
    }

    .card .number {
        position: absolute;
        top: 42px;
        left: 20px;
        /* transform: translate(-50%, -50%); */
    }

    .card .number h3 {
        font-weight: 600;
        font-size: 12px;
    }

    .select-box {
        background: url('../arrow.svg') no-repeat 95px center;
        background-size: 15px;
    }
    .footerLeftSection, .footerRightSection{
        display: flex;
        align-items: center;
        justify-content: start;
        padding: 20px 0;
        gap: 20px;
    }
    .footerRightSection {
        justify-content: end;
    }

    .notification-main-menu-sponser .MuiPaper-elevation8{
        top: 15px !important;
        border-radius: 20px;
    }
    .notification-main-menu-sponser {
        top: 60px;
        position: relative;
    }
    .notification-main-menu-sponser:after{
        width: 0; 
        height: 0; 
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #fff;
        position: absolute;
        content: "";
        top: 6px;
        right: 278px;
        margin: 0 auto;
    }
   
    .notification-main-menu-sponser:before{
        width: 0;
        height: 0;
        border-left: 17px solid transparent;
        border-right: 18px solid transparent;
        border-bottom: 14px solid #c5c5c5;
        position: absolute;
        content: "";
        top: 5px;
        right: 271px;
        margin: 0 auto;
        filter: blur(4px);
      }
      .notification-main-menu-switch:before {
        top: 40px;
        right: 267px;
      }
      .notification-main-menu-switch:after {
        top: 42px;
        right: 275px;
      }
     
      .notification-main-menu-switch .MuiPaper-elevation8 {
        top: 52px !important;
      }
      .talent-slider-wrapper ul {
        gap: 20px;
      }
      .active-bids-details {
        display: flex;
        align-items: end;
        justify-content: space-between;
        flex-direction: column;
      }
     
   
    .css-view-1dbjc4n button.dropdown-btn {
        padding: 0 10px;
    }

    .css-view-1dbjc4n button.btn-green {
        padding: 15px 20px;
    }

    /* .css-view-1dbjc4n ul.main-list {
        margin-left: 0px;
    } */

    /* ======= Responsive Header ======= */
    .mobile-view-responsive {
        display: none;
    }

   

    .sponsor-header-searchbar {
        box-shadow: none !important;
        position: relative;
        display: block !important;
    }

    .sponsor-header-searchbar > div {
        margin-left: auto;
        width: 100%;
    }

    .sponsor-header-searchbar input {
        border: 1px solid #E4E6E8;
        border-radius: 31px;
        box-shadow: none;
        padding: 10px 10px 10px 25px;
        color: gray;
    }

   
    .sponsor-header-searchbar button {
        right: 60px;
        background: #00a7a2;
        position: absolute;
        /* right: 65px; */
        bottom: -5px;
        color: white;
    }

    .usertime .MuiAvatar-root {
        height: 50px;
        width: 50px;
    }

    .sponsor-header-searchbar button:nth-child(2) {
        transform: scale(1);
        margin-right: 0;
        color: white;
        font-weight: bolder;
        right: 13px;
    }

    .sponsor-header-searchbar button:hover {
        background: #00A7A2;
    }

    .sponsor-header-searchbar button:nth-child(1) {
        margin-right: 20px;
    }

    .ForwardRef-searchContainer-26 {
        width: 97% !important;
        margin: 0 auto !important;
    }

.notification-bar {
    background: #00A7A2;
    color: #fff;
    border-radius: 100%;
    display: inline-block;
    width: 18px;
    height: 18px;
    position: absolute;
    top: -2px;
    right: -5px;
    padding: 1px;
  }
  .notification-count {
    position: relative;
  }
  .notification-bar >span{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 10px;
  }

    /* .sponsor-header-searchbar .jss13 {
    margin-right: 0px !important;
}
.sponsor-header-searchbar .jss16 {
    width: 100%;
    margin: 0;
} */
    .active-bids-block-wrapper .active-bids-block-content img {
        border-radius: 50%;
        height: 40px;
    }

    .active-bids-block-wrapper .active-bids-block-content {
        align-items: center;
        gap: 10px;
    }
    .myProjectHeight {
        margin-bottom: 50px;
        border-bottom: 1px solid #dfdfdf;
    }

    /* ::-webkit-scrollbar {
        width: 8px;
      } */
      
      /* Track */
      /* ::-webkit-scrollbar-track {
        box-shadow:  gray; 
        border-radius: 10px;
      } */
       
      /* Handle */
      /* ::-webkit-scrollbar-thumb {
        background: #e0e0e0; 
        border-radius: 10px;
      } */
      .resDashboardTable {
        min-width: 150px;
        display: flex;
        align-items: center;
      }
    @media screen and (min-width:1440px) {
        .react-multiple-carousel__arrow--left {
            left: 95px !important;
        }

        .react-multiple-carousel__arrow--right {
            right: 105px !important;
        }

    }

    @media screen and (max-width:1350px) {
        .react-multiple-carousel__arrow--left {
            left: 50px !important;
        }

        .react-multiple-carousel__arrow--right {
            right: 62px !important;
        }
    }
    @media(max-width:1276px) {
        .header-logo {
            max-width: 250px;
            min-width: 250px;
        }

        /*.header-navigation {
            justify-content: center;
            order: 0;
            flex-wrap: wrap;
        }*/
    }

    

    

    @media screen and (max-width:1200px) {
        .container {
            max-width: 1200px;
        }

        /* 
        .header-navigation {
            order: 3;
            width: 100%;
            margin: 20px 0;
        } */

    }

    
    @media screen and (max-width: 1199px) {
        /* .container {
            max-width: 960px;
        } */

        .recharts-surface svg {
            width: 668px !important;
        }

        

        svg.recharts-surface {
            width: 568px !important;
            /* margin-left: 10px; */
            /* margin-left: 30px; */
        }

        /* .pie-block {
            transform: scale(1.2);
            margin-left: 0;
        } */

        .pie-block svg.recharts-surface {
            width: 270px !important;
        }

        .react-multiple-carousel__arrow--left {
            left: 10px !important;
        }

        .react-multiple-carousel__arrow--right {
            right: 40px !important;
        }

        .talent-block {
            display: flex;
            align-items: center;
            /* flex-wrap: wrap; */
            padding: 20px 25px;
            border: 1px solid #e4e6e8;
            max-width: 100%;
            border-radius: 20px;

        
        }
    }
    @media(max-width:1158px) {
        .header-logo {
            max-width: 120px;
            min-width: 120px;
        }

        .header-navigation.d-flex {
            display: none;

        }

        .mobile-view-header-navigation {
            flex-direction: column;
        }

        .mobile-view-responsive {
            display: block;

        }

        .mobile-view-responsive .mobile-view-header-navigation {
            position: absolute;
            right: 0;
            top: 0;
        } 
    }
    @media(max-width:1158px) {
        .header-logo {
            max-width: 120px;
            min-width: 120px;
        }

        .header-navigation.d-flex {
            display: none;

        }

        .mobile-view-header-navigation {
            flex-direction: column;
        }

        .mobile-view-responsive {
            display: block;

        }

        .mobile-view-responsive .mobile-view-header-navigation {
            position: absolute;
            top: 64px;
            left: 11px;
            flex-direction: column;
            width: 100%;
            display: none;
            border: 1px solid;
            max-width: 200px;
            padding: 20px;
            background: #F2F2F6;
            border-radius: 15px;
            border: 1px solid;
            max-width: 200px;
            z-index: 1;
        }

        .mobile-view-responsive .mobile-view-header-navigation.show-menu {
            display: block;

        }

        .mobile-view-responsive ul.navigation {
            flex-direction: column;
            align-items: center;
            margin: 0;
        }

        .mobile-view-header-navigation button.btn-green {
            text-align: center;
            width: 100%;
            margin-top: 10px;
        }

        .mobile-view-header-navigation ul.navigation li.active:after {
            background: none;
        }

        .mobile-view-header-navigation ul.navigation li.active:after {
            background: none;
        }

        .react-multi-carousel-list .react-multiple-carousel__arrow {
            z-index: 1 !important;
        }

        .mobile-view-responsive svg.menu-heder {
            border: 1px solid #f2f2f2;
            width: 15px;
            height: 15px;
            border-radius: 4px;
            padding: 5px;
            background: #f2f2f2;
        }
    }

    @media screen and (max-width: 991px) {
        /* .container {
            max-width: 720px;
        } */
        .react-multiple-carousel__arrow--left {
            left: 20px !important;
        }
        .react-multiple-carousel__arrow--right {
            right: 20px !important;
        }

        
        .review-block.d-flex {
            display: block;
        }

        /* .pie-block {
            transform: scale(1.5);
        } */

        /* svg.recharts-surface {
            width: 750px !important;
            transform: scale(1.1);
            margin-left: 20px;
        } */

        .pie-block svg.recharts-surface {
            width: 170px !important;
            height: 300px !important;
        }

        .recharts-wrapper {
            width: 170px !important;
            height: 300px !important;
        }

        .rating-star,
        .active-bids-details span {
            display: inline-block;
            margin-top: 10px;
        }

        /* .header-logo {
            width: 100%;
            max-width: 100%;
            text-align: center;
            margin-bottom: 20px;
        } */
        .block-heading.d-flex {
            flex-direction: column;
        }
        [href="/Notificationsponser"] .MuiButton-label{
            position: relative;
          }
          [href="/Notificationsponser"] .MuiButton-label .notification-bar {
            right: -20px;
            top: 2px;
          }
          [href="/Notificationsponser"] .MuiButton-label .notification-bar > span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -40%);
            font-size: 10px;
          }
          
    }

    @media(max-width:875px) {
        .header-content {
            flex: 1;
        }

        .site-header {
            justify-content: unset;
        }

        .mobile-view-responsive {
            margin-right: 10px;
        }

        .header-email-name {
            display: none;
        }
    }

    @media screen and (max-width: 767px) {
        /* .container {
            max-width: 540px;
        } */

        .react-multiple-carousel__arrow--left {
            left: 20px !important;
        }
        .react-multiple-carousel__arrow--right {
            right: 20px !important;
        }

        .righttelent-block,
        .news-block {
            width: 44%;
            margin-bottom: 20px;
        }

        .righttelent-block-wrapper,
        .news-block-wrapper {
            flex-wrap: wrap;
        }
        .design-logo-block {
            padding: 20px 20px;
        }
        .design-content.d-flex {
            gap: 15px;
        }
        .footerLeftSection, .footerRightSection {
            justify-content: center;
        }

        svg.recharts-surface {
            width: 600px !important;
            transform: scale(1.1);
            /* margin-left: 15px; */
        }
        .resActiveBids {
            width: 250px;
        }
        /* .bannerclass {
            height: 500px;
        } */
        .banner-containt h1,  h2 {
            font-size: 24px !important;
        }
        .banner-containt p {
            font-size: 16px;
            max-width: 80%;
            margin: 0 auto;
        }
        .resFlexDir p {
            font-size: 20px !important;
        }
        
    }

    @media screen and (max-width:575px) {
        .container {
            max-width: 100%;
            padding: 0;
        }

        .react-multiple-carousel__arrow--left {
            left: 20px !important;
        }
        .react-multiple-carousel__arrow--right {
            right: 20px !important;
        }
        .react-multi-carousel-item {
            display: flex;
            justify-content: center;
            width: 80%;
        }
        .talent-block {
            padding: 20px 25px;
            width: 100%;
        }
        svg.recharts-surface {
            width: 500px !important;
            transform: scale(1.1);
            /* margin-left: 10px; */
        }
        .MuiGrid-spacing-xs-3 {
            width: 100% !important;
            margin: 0 !important;
          }
          .active-bids-block-wrapper.d-flex {
            display: block;
          }
          .active-bids-details {
            align-items: center;
            flex-direction: row;
            gap: 20px;
            justify-content: flex-start;
          }
          .ForwardRef-searchContainer-26 {
            margin: 0 auto !important;
          }
    }
    @media screen and (max-width:480px) {
        svg.recharts-surface {
            width: 280px !important;
            transform: scale(1.1);
            margin-left: 5px;
        }
        .resFlexDir {
            flex-direction: column;
            align-items: flex-start !important;
        }
        .MuiGrid-spacing-xs-3 .MuiGrid-item {
            width: 90% !important;
            padding: 12px 0 !important;
            text-align: center;
            margin: 0 auto;
        }
        .MuiGrid-spacing-xs-3 .MuiGrid-item .custom-width {
            width: auto !important;
        }
        .talent-block {
            width: 60%;
        }
        .talent-slider-wrapper ul {
            gap: 0;
        }
    }
    @media(max-width: 375px) {
        .resAccountName {
            display: none  !important;
        }
    }

    @media(max-width: 320px) {
        .usertime.d-flex {
            gap: 0 !important;
        }
    }